import React from 'react';
import { connect } from 'react-redux';
import { useRoutes } from 'hookrouter';
import Typography from '@material-ui/core/Typography';

import AdminMasterDetail from './AdminMasterDetail';
import AdminHome from './AdminHome';
import AdminUsers from './Users/AdminUsers';
import Strings from '../../locales/en';

const nestedSecureRoutes = {
    '/jewelry': () => <Typography variant="h2">{Strings.admin.jewelry.title}</Typography>,
    '/users': () => <AdminUsers />,
    '/': () => <AdminHome />,
};

const SecureAdmin = () => {
    const routeResult = useRoutes(nestedSecureRoutes);
    return <AdminMasterDetail>{routeResult}</AdminMasterDetail>;
};

function mapStateToProps(state) {
    return {
        root: state.nav.root,
    };
}

export default connect(mapStateToProps)(SecureAdmin);
