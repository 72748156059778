import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Strings from '../../locales/en';

const styles = theme => ({});

function UserHome(props) {
    return <div>{Strings.user.home.comingSoon}</div>;
}

const mapStateToProps = state => {
    return {
        profile: state.user.profile,
    };
};

const styledUserHome = withStyles(styles)(UserHome);
export default connect(mapStateToProps)(styledUserHome);
