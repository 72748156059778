import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4D45BF',
        },
        secondary: {
            main: '#F9885E',
        },
        standards: {
            black: '#000000',
            doom: '#333333',
            blue: '#6978F6',
            cream: '#F4F2EC',
            creamDark: '#DBDAD5',
            grey: '#979797',
            greyDark: '#707070',
            orange: '#F9885E',
            purple: '#4D45Bf',
            turquoise: '#27C8AC',
            yellow: '#F5C16E',
            veryLight: '#FAFAFA',
            white: '#FFFFFF',
        },
    },
    typography: {
        useNextVariants: true,
        h1: {
            fontFamily: 'museo-sans, sans-serif',
            fontWeight: 100,
            fontSize: '2rem',
            marginTop: '1rem',
            marginBottom: '1.5rem',
        },
        h2: {
            fontFamily: 'museo-sans, sans-serif',
            fontWeight: 100,
            fontSize: '1.58018rem',
        },
        h3: {
            fontFamily: 'museo-sans-rounded, sans-serif',
            fontWeight: 700,
            fontSize: '1.3207rem',
        },
        h4: {
            fontFamily: 'museo-sans-rounded, sans-serif',
            fontWeight: 700,
            fontSize: '1.16036rem',
        },
        h5: {
            fontFamily: 'museo-sans, sans-serif',
            fontWeight: 300,
            fontSize: '1.06125rem',
        },
        h6: {
            fontFamily: 'museo-sans, sans-serif',
            fontWeight: 300,
            fontSize: '1rem',
        },
        body: {
            fontFamily: 'museo-sans, sans-serif',
            fontSize: '14px',
        },
        fontFamily: 'museo-sans, sans-serif',
    },
    spacing: {
        small: 8,
        medium: 16,
        large: 24,
        huge: 36,
    },
});

export default theme;
