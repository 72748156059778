import React from 'react';
import { useRoutes } from 'hookrouter';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import axios from 'axios';

import * as RoutePrefixes from './constants/RoutePrefixes';
import { store, persistor } from './store';
import { signOut } from './actions/auth';
import NotFound from './pages/NotFound';
import Reset from './pages/Reset';
import SecureAdmin from './pages/SecureAdmin';
import SecureUser from './pages/SecureUser';
import SignIn from './pages/SignIn';
import theme from './bits/FlareTheme';

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            store.dispatch(signOut());
        }
        return Promise.reject(error);
    }
);

const routes = {
    [`${RoutePrefixes.Admin}*`]: () => <SecureAdmin />,
    [`${RoutePrefixes.User}*`]: () => <SecureUser />,
    [`${RoutePrefixes.Manufacturing}*`]: () => <div>Manu</div>,
    [`${RoutePrefixes.Reset}*`]: () => <Reset />,
    [RoutePrefixes.Insecure]: () => <SignIn />,
};

const App = () => {
    const routeResult = useRoutes(routes);
    return (
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <MuiThemeProvider theme={theme}>
                    <React.Fragment>{routeResult || <NotFound />}</React.Fragment>
                </MuiThemeProvider>
            </PersistGate>
        </ReduxProvider>
    );
};

export default App;
