import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    container: {
        padding: theme.spacing.medium,
        borderTop: `1px dotted ${theme.palette.standards.grey}`,
        borderLeft: `3px solid transparent`,
    },
    selected: {
        borderLeft: `6px solid ${theme.palette.primary.main}`,
    },
    detail: {
        fontSize: '0.8rem',
        color: theme.palette.standards.doom,
    },
});

const UserListItem = props => {
    const { classes, onClick, selected } = props;
    return (
        <div
            className={classNames([classes.container, 'user-list-item', selected && classes.selected])}
            onClick={onClick}
            onKeyUp={onClick}
            role="link"
            tabIndex={0}
        >
            <div key={props.id}>
                <div>{props.name}</div>
                <div className={classNames([classes.detail])}>{props.email}</div>
                <div className={classNames([classes.detail])}>{props.phone}</div>
            </div>
        </div>
    );
};

export default withStyles(styles)(UserListItem);
