export const AUTH_FAILURE = 'flare.app.auth.FAILURE';
export const AUTH_REQUEST = 'flare.app.auth.REQUEST';
export const AUTH_RESET = 'flare.app.auth.RESET';
export const AUTH_SUCCESS = 'flare.app.auth.SUCCESS';
export const AUTH_CLEAR_ERROR = 'flare.app.auth.CLEAR_ERROR';

export const RESET_TOKEN_REQUEST = 'flare.app.auth.reset.REQUEST';
export const RESET_TOKEN_SUCCESS = 'flare.app.auth.reset.SUCCESS';
export const RESET_TOKEN_FAILURE = 'flare.app.auth.reset.FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'flare.app.auth.changePassword.REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'flare.app.auth.changePassword.SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'flare.app.auth.changePassword.FAILURE';

export const ROOT_CHANGED = 'flare.app.ROOT_CHANGED';

export const GET_RECENT_EVENTS_REQUEST = 'flare.app.admin.getRecentEvents.REQUEST';
export const GET_RECENT_EVENTS_SUCCESS = 'flare.app.admin.getRecentEvents.SUCCESS';
export const GET_RECENT_EVENTS_FAILURE = 'flare.app.admin.getRecentEvents.FAILURE';

export const SET_RECENT_EVENTS_TIMEFRAME = 'flare.app.admin.setRecentEventsTimeline';

export const SEARCH_USERS_REQUEST = 'flare.app.admin.searchUsers.REQUEST';
export const SEARCH_USERS_SUCCESS = 'flare.app.admin.searchUsers.SUCCESS';
export const SEARCH_USERS_FAILURE = 'flare.app.admin.searchUsers.FAILURE';
export const RESET_SEARCH_USERS = 'flare.app.admin.resetSearchUsers';

export const UPDATE_USER_REQUEST = 'flare.app.admin.updateUser.REQUEST';
export const UPDATE_USER_SUCCESS = 'flare.app.admin.updateUser.SUCCESS';
export const UPDATE_USER_FAILURE = 'flare.app.admin.updateUser.FAILURE';
