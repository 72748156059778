import { persistStore, persistCombineReducers } from 'redux-persist';
import { createStore, compose, applyMiddleware } from 'redux';
import storage from 'redux-persist/lib/storage';
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable';

import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { LogReduxChanges } from '../constants/FlareConfig';
import * as reducers from '../reducers/index';
import initialState from '../reducers/initialState';

let middleware = [thunk];
if (LogReduxChanges) {
    middleware = [...middleware, logger];
} else {
    middleware = [...middleware];
}

const transformerConfig = {
    whitelistPerReducer: {
        nav: ['root'],
        user: ['profile', 'crews', 'devices', 'authToken', 'settings', 'hasActiveFlare', 'role'],
    },
};

const persistConfig = {
    key: 'flare-root',
    storage,
    stateReconciler: seamlessImmutableReconciler,
    transforms: [seamlessImmutableTransformCreator(transformerConfig)],
    version: 1,
    timeout: null,
};

const combinedReducer = persistCombineReducers(persistConfig, reducers);
const enhancer = compose(applyMiddleware(...middleware));
const store = createStore(combinedReducer, initialState, enhancer);
const persistor = persistStore(store);

export { store, persistor };
