import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import FlareRoundedIcon from '@material-ui/icons/FlareRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import FlareDeviceID from '../../../bits/FlareDeviceID';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing.medium,
        paddingRight: theme.spacing.medium,
        paddingTop: theme.spacing.small,
        paddingBottom: theme.spacing.small,
        borderBottom: `1px solid ${theme.palette.standards.veryLight}`,
    },
    header: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        flex: 2,
        flexGrow: 3,
        color: theme.palette.standards.black,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    timestamp: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        fontSize: '0.8rem',
        whiteSpace: 'nowrap',
    },
    inlineIcon: {
        display: 'inline-flex',
        marginRight: '5px',
    },
    verticalMiddle: {
        verticalAlign: 'middle',
    },
    details: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.standards.grey,
        fontSize: '0.8rem',
    },
});

/**
 * Render an event with the following properties:
 * + answers
 * + details (json)
 * + device_id
 * + event_type_id
 * + icon
 * + id
 * + is_home
 * + is_wearing
 * + labels
 * + latitude
 * + longitude
 * + note_id
 * + notes
 * + timestamp
 * + user_id
 * + device_id
 */
const EventViewerListItem = props => {
    const { classes } = props;
    return (
        <div className={classNames([classes.container])}>
            <div className={classNames([classes.header])}>
                <div className={classNames([classes.title])}>{props.title}</div>
                <div className={classNames([classes.timestamp])}>
                    <div className={classNames([classes.inlineIcon, classes.verticalMiddle])}>
                        <AccessTimeRoundedIcon fontSize="small" />
                    </div>
                    <span className={classNames([classes.verticalMiddle])}>
                        {moment(props.timestamp).format('M/D HH:mm:ss')}
                    </span>
                </div>
            </div>
            <div className={classNames([classes.details])}>
                {props.userID && (
                    <div>
                        <div className={classNames([classes.inlineIcon, classes.verticalMiddle])}>
                            <PersonRoundedIcon fontSize="small" />
                        </div>
                        <span className={classNames([classes.verticalMiddle])}>{props.userID}</span>
                    </div>
                )}
                {props.deviceID && (
                    <div>
                        <div className={classNames([classes.inlineIcon, classes.verticalMiddle])}>
                            <FlareRoundedIcon fontSize="small" />
                        </div>
                        <span className={classNames([classes.verticalMiddle])}>
                            <FlareDeviceID value={props.deviceID} />
                        </span>
                    </div>
                )}
            </div>
            <div className={classNames([classes.tags])} />
        </div>
    );
};

EventViewerListItem.defaultProps = {
    isHome: false,
    isWearing: false,
};

EventViewerListItem.propTypes = {
    title: PropTypes.string.isRequired,
    isHome: PropTypes.bool,
    isWearing: PropTypes.bool,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    timestamp: PropTypes.string.isRequired,
    userID: PropTypes.number,
    deviceID: PropTypes.number,
};

export default withStyles(styles)(EventViewerListItem);
