import * as types from '../actions/types';
import initialState from './initialState';
import * as RootPrefixes from '../constants/RoutePrefixes';
import UserRoleTypes from '../constants/Roles';

export default function nav(state = initialState.nav, action = {}) {
    switch (action.type) {
    case types.ROOT_CHANGED:
        return state.merge({
            root: action.root,
        });

    case types.AUTH_SUCCESS: {
        let root = null;
        switch (action.data.role) {
        case UserRoleTypes.Admin:
            root = RootPrefixes.Admin;
            break;
        case UserRoleTypes.Manufacturing:
            root = RootPrefixes.Manufacturing;
            break;
        case UserRoleTypes.User:
        default:
            root = RootPrefixes.User;
            break;
        }

        return state.merge({
            root,
        });
    }

    case types.AUTH_RESET:
        return initialState.nav;

    default:
        return state;
    }
}
