import React from 'react';

import { withSize } from 'react-sizeme';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Strings from '../../../locales/en';

const BREAKPOINT_FOR_DETAIL_TOGGLE = 740;

const styles = theme => ({
    container: {
        padding: theme.spacing.huge,
        backgroundColor: theme.palette.standards.white,
    },
    containerMobile: {
        marginTop: theme.spacing.huge,
    },
    header: {
        marginBottom: theme.spacing.huge,
    },
    formField: {
        marginBottom: theme.spacing.medium,
    },
    formFieldDesktop: {
        display: 'block',
    },
    desktopFormActions: {
        marginTop: theme.spacing.large,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    desktopFormActionButton: {
        margin: theme.spacing.small,
    },
    readyOnlyFields: {
        marginTop: theme.spacing.small,
        marginBottom: theme.spacing.large,
    },
});

class AdminUserDetail extends React.Component {
    constructor(props) {
        super(props);

        const { user } = props;
        this.state = {
            user,
        };

        this.fields = ['first_name', 'last_name', 'phone', 'email'];
        this.fieldsReadOnly = ['created', 'updated'];
        this.checkboxes = ['active', 'viewed_tutorial'];
        this.innerRef = () => Function;
    }

    componentDidMount() {
        const { innerRef } = this.props;
        if (innerRef) {
            innerRef(this);
        }
    }

    componentWillUnmount() {
        const { innerRef } = this.props;
        if (innerRef) {
            innerRef(undefined);
        }
    }

    /**
     * Handle new user selection in master view
     */
    static getDerivedStateFromProps(props, state) {
        const { user: newUser } = props;
        const { user: currentUser } = state;

        // Admin selected a different user in master view
        if (newUser && (newUser.id !== currentUser.id || newUser.updated !== currentUser.updated)) {
            return {
                user: newUser,
            };
        }

        // Admin reset selection in master view
        if (!newUser) {
            return {
                user: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    active: false,
                    viewed_tutorial: false,
                    created: null,
                    updated: null,
                },
            };
        }

        return state;
    }

    updateField(field, value) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [field]: value,
            },
        });
    }

    resetForm() {
        const { user } = this.props;
        this.setState({
            user,
        });
    }

    saveUserDetails() {
        const { onSaveUser } = this.props;
        const { user } = this.state;
        onSaveUser(user);
    }

    render() {
        const { classes, size, onSaveUser } = this.props;
        const { user } = this.state;
        const { width } = size;
        const useMobileLayout = width < BREAKPOINT_FOR_DETAIL_TOGGLE;
        return (
            <div className={classNames([classes.container, useMobileLayout && classes.containerMobile])}>
                <div className={classNames([classes.header])}>
                    <Typography variant="h6" color="primary">
                        {Strings.admin.users.detail.title}
                    </Typography>
                    <Typography variant="h2">
                        {user.first_name} {user.last_name}
                    </Typography>
                </div>
                <div className={classes.readyOnlyFields}>
                    {this.fieldsReadOnly.map(f => (
                        <TextField
                            key={f}
                            value={user[f] || Strings.admin.users.detail.neverHappened}
                            label={Strings.admin.users.detail.form[f]}
                            contentEditable={false}
                            fullWidth
                            className={classNames([classes.formField, !useMobileLayout && classes.formFieldDesktop])}
                            disabled
                        />
                    ))}
                </div>
                <div className={classes.form}>
                    {this.fields.map(f => (
                        <TextField
                            key={f}
                            value={user[f]}
                            onChange={e => this.updateField(f, e.currentTarget.value)}
                            label={Strings.admin.users.detail.form[f]}
                            fullWidth
                            className={classNames([classes.formField, !useMobileLayout && classes.formFieldDesktop])}
                        />
                    ))}
                    {this.checkboxes.map(f => (
                        <FormControlLabel
                            key={f}
                            control={
                                <Checkbox
                                    checked={user[f] || false}
                                    label={Strings.admin.users.detail.form[f]}
                                    color="primary"
                                    onChange={e => this.updateField(f, e.target.checked)}
                                />
                            }
                            label={Strings.admin.users.detail.form[f]}
                            className={classes.checkbox}
                        />
                    ))}
                </div>
                {!useMobileLayout && (
                    <div className={classes.desktopFormActions}>
                        <Button
                            variant="text"
                            onClick={() => this.resetForm()}
                            className={classes.desktopFormActionButton}
                        >
                            {Strings.generic.resetForm}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onSaveUser(user)}
                            className={classes.desktopFormActionButton}
                        >
                            {Strings.generic.save}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

const styledAdminUserDetail = withStyles(styles)(AdminUserDetail);
export default withSize({ refreshMode: 'debounce' })(styledAdminUserDetail);
