import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import Typography from '@material-ui/core/Typography';

import notFoundAnimation from '../../assets/anim/placeholder-wait.json';
import Strings from '../../locales/en';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
    },
});

const NotFound = props => {
    const { classes } = props;
    return (
        <div className={classes.container}>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: notFoundAnimation,
                }}
            />
            <Typography variant="h1">{Strings.notFound.title}</Typography>
            <Typography variant="body1">{Strings.notFound.details}</Typography>
        </div>
    );
};

export default withStyles(styles)(NotFound);
