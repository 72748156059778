import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import UserListItem from './UserListItem';

import './userList.css';

const styles = theme => ({
    container: {},
});

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: props.selectedIndex,
        };
    }

    onPressItem(itemIndex) {
        this.setState({
            selection: itemIndex,
        });
        const { onPressUser } = this.props;
        onPressUser(itemIndex);
    }

    render() {
        const { classes, users } = this.props;
        const { selection } = this.state;
        return (
            <div className={classNames([classes.container])}>
                {users.map((u, index) => (
                    <UserListItem
                        key={u.id}
                        email={u.email}
                        phone={u.phone}
                        name={`${u.last_name}, ${u.first_name}`}
                        onClick={() => this.onPressItem(index)}
                        selected={index === selection}
                    />
                ))}
            </div>
        );
    }
}

export default withStyles(styles)(UserList);
