import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.standards.veryLight,
        padding: '0.1rem 0.5rem 0.1rem 1rem',
        alignItems: 'center',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
    titleArea: {
        flex: 7,
        fontFamily: 'museo-sans, sans-serif',
        fontWeight: 700,
        fontSize: '0.9rem',
        cursor: 'pointer',
        textSelect: 'none',
    },
    titleAreaNoClose: {
        paddingTop: '0.9rem',
        paddingBottom: '0.9rem',
    },
    actionArea: {
        flex: 3,
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

const WidgetTitle = props => {
    const { classes, title, canClose, onClose } = props;
    return (
        <div className={classes.container}>
            <div className={classNames([classes.titleArea, !canClose && classes.titleAreaNoClose])}>{title}</div>
            <div className={classes.actionArea}>
                {canClose && (
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => onClose(title)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(WidgetTitle);
