import Immutable from 'seamless-immutable';
import * as RoutePrefixes from '../constants/RoutePrefixes';

// eslint-disable-next-line
export default Immutable({
    admin: {},
    nav: {
        root: RoutePrefixes.Insecure, // @see RoutePrefixes for role-specific routes
    },
    user: {
        contacts: [],
        contactsCrewLookup: {},
        crewEvents: [],
        crews: [],
        devices: [],
        hasActiveFlare: false,
        permissions: {
            bluetooth: false,
            contacts: false,
            location: false,
            notification: false,
        },
        profile: {},
        token: null,
    },
    stats: {},
});
