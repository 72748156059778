import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import logo from '../../assets/img/logo-aura.png';

const styles = theme => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '1rem',
        backgroundColor: theme.palette.primary.main,
    },
    logo: {
        height: '5rem',
    },
    logoArea: {
        padding: '2rem 0',
    },
});

const FlareDrawer = props => {
    const { children, classes, ...other } = props;
    return (
        <Drawer {...other}>
            <div className={classes.container}>
                <div className={classes.logoArea}>
                    <img src={logo} alt="Flare" className={classes.logo} />
                </div>
                <div>{children}</div>
            </div>
        </Drawer>
    );
};

export default withStyles(styles)(FlareDrawer);
