import React from 'react';
import PropTypes from 'prop-types';

import { DeviceIDLabelLength, MaxDeviceID } from '../constants/FlareConfig';

export default class FlareDeviceID extends React.PureComponent {
    static getJewelryLabelFromDeviceID(deviceID) {
        const rawHex = deviceID.toString(16);
        const paddingRequired = DeviceIDLabelLength - rawHex.length;
        return `${'0'.repeat(paddingRequired)}${rawHex}`.toUpperCase();
    }

    static isValid(deviceID) {
        if (!deviceID) {
            return false;
        }
        let valid = true;
        const cleanedID = deviceID.replace(/[^0-9A-F]+/g, '');
        if (cleanedID.length !== DeviceIDLabelLength) {
            valid = false;
        }
        const parsedID = parseInt(cleanedID, 10);
        if (parsedID < 1 || parsedID > MaxDeviceID) {
            valid = false;
        }
        return valid;
    }

    render() {
        const { value } = this.props;
        const display = FlareDeviceID.getJewelryLabelFromDeviceID(value);
        return <span {...this.props}>{display}</span>;
    }
}

FlareDeviceID.propTypes = {
    value: PropTypes.number.isRequired,
};
