import React from 'react';
import { connect } from 'react-redux';

import Poll from '@material-ui/icons/Poll';

import Strings from '../../locales/en';
import TitleBar from '../../bits/TitleBar';
import { FlareDrawer, FlareDrawerLink } from '../../bits/FlareDrawer/index';

class UserMasterDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDrawer: false,
        };
    }

    toggleDrawer(openDrawer) {
        let nextDrawerState = openDrawer;
        if (typeof openDrawer === 'undefined') {
            const { openDrawer: drawerIsOpen } = this.state;
            nextDrawerState = !drawerIsOpen;
        }
        this.setState({
            openDrawer: nextDrawerState,
        });
    }

    render() {
        const { root, children } = this.props;
        const { openDrawer } = this.state;
        return (
            <div>
                <TitleBar onClickMenu={() => this.toggleDrawer()} />
                <FlareDrawer open={openDrawer} onClose={() => this.toggleDrawer(false)}>
                    <FlareDrawerLink href={`${root}/`} label={Strings.admin.home.title} icon={<Poll />} />
                    <FlareDrawerLink href={`${root}/jewelry`} label={Strings.user.jewelry.title} />
                    <FlareDrawerLink href={`${root}/privacy`} label={Strings.user.privacy.title} />
                </FlareDrawer>
                <div>{children}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        root: state.nav.root,
    };
}

export default connect(mapStateToProps)(UserMasterDetail);
