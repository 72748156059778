import React from 'react';
import { connect } from 'react-redux';
import { useRoutes } from 'hookrouter';
import Typography from '@material-ui/core/Typography';

import UserMasterDetail from './UserMasterDetail';
import UserHome from './UserHome';
import Strings from '../../locales/en';

const nestedSecureRoutes = {
    '/jewelry': () => <Typography variant="h2">{Strings.user.jewelry.title}</Typography>,
    '/privacy': () => <Typography variant="h2">{Strings.user.privacy.title}</Typography>,
    '/': () => <UserHome />,
};

const SecureUser = () => {
    const routeResult = useRoutes(nestedSecureRoutes);
    return <UserMasterDetail>{routeResult}</UserMasterDetail>;
};

function mapStateToProps(state) {
    return {
        root: state.nav.root,
    };
}

export default connect(mapStateToProps)(SecureUser);
