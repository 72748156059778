import React from 'react';
import { connect } from 'react-redux';

import { withSize } from 'react-sizeme';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';

import AdminUserDetail from './AdminUserDetail';
import UserList from '../../../bits/UserList';
import { resetSearchForUsers, saveChangestoUser, searchForUsers } from '../../../actions/admin';
import Strings from '../../../locales/en';

const BREAKPOINT_FOR_DETAIL_TOGGLE = 740;

const styles = theme => ({
    container: {},
    searchFormButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing.medium,
        paddingRight: theme.spacing.medium,
        paddingBottom: theme.spacing.medium,
        backgroundColor: theme.palette.standards.creamDark,
    },
    masterDetailContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100vh',
    },
    master: {
        flex: 1,
        flexGrow: 0.7,
        borderRight: `1px solid ${theme.palette.standards.greyDark}`,
    },
    detail: {
        flex: 4,
        flexGrow: 2,
        backgroundColor: theme.palette.standards.white,
    },
    dialogAppBar: {},
    dialogActionButtons: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            selectedUserIndex: null,
            showUserDialog: false,
        };
        this.detailView = React.createRef();
    }

    onPressReset() {
        const { dispatch } = this.props;
        dispatch(resetSearchForUsers());
        this.setState({
            searchText: '',
            selectedUserIndex: null,
        });
    }

    onPressSearch(e) {
        e.preventDefault();
        const { dispatch, token } = this.props;
        const { searchText } = this.state;
        if (searchText.length > 0) {
            dispatch(searchForUsers(token, searchText));
        } else {
            this.onPressReset();
        }
    }

    onPressUser(index) {
        const { size } = this.props;
        const showDetail = size.width > BREAKPOINT_FOR_DETAIL_TOGGLE;
        this.setState({
            showUserDialog: !showDetail,
            selectedUserIndex: index,
        });
    }

    handleSearchTextChange(v) {
        this.setState({
            searchText: v,
        });
    }

    closeUserDetailDialog() {
        this.setState({
            showUserDialog: false,
            selectedUserIndex: null,
        });
    }

    saveUserDetail(user) {
        const { token, dispatch } = this.props;
        dispatch(saveChangestoUser(token, user));
    }

    render() {
        const { classes, results, size } = this.props;
        const { searchText, selectedUserIndex, showUserDialog } = this.state;
        const showDetail = size.width > BREAKPOINT_FOR_DETAIL_TOGGLE;
        return (
            <div className={classNames([showDetail && classes.masterDetailContainer])}>
                <div className={classNames([classes.container, showDetail && classes.master])}>
                    <div>
                        <form noValidate autoComplete="off" onSubmit={e => this.onPressSearch(e)}>
                            <div className={classes.header}>
                                <TextField
                                    id="standard-name"
                                    label={Strings.admin.users.searchFieldName}
                                    className={classes.textField}
                                    value={searchText}
                                    onChange={e => this.handleSearchTextChange(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                />
                                <div className={classes.searchFormButtons}>
                                    <Button onClick={() => this.onPressReset()} variant="text">
                                        {Strings.admin.users.clearButtonLabel}
                                    </Button>
                                    <Button onClick={e => this.onPressSearch(e)} color="primary" variant="contained">
                                        {Strings.admin.users.searchButtonLabel}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <UserList users={results} onPressUser={index => this.onPressUser(index)} />
                </div>
                {showDetail && (
                    <div className={classes.detail}>
                        {selectedUserIndex !== null && (
                            <AdminUserDetail
                                index={selectedUserIndex}
                                user={results[selectedUserIndex]}
                                onSaveUser={user => this.saveUserDetail(user)}
                            />
                        )}
                    </div>
                )}
                <Dialog
                    fullScreen
                    open={showUserDialog}
                    onClose={() => this.closeUserDetailDialog()}
                    disableEscapeKeyDown
                >
                    <AppBar className={classes.dialogAppBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => this.closeUserDetailDialog()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <div className={classes.dialogActionButtons}>
                                <Button
                                    color="inherit"
                                    onClick={() => {
                                        this.detailView.resetForm();
                                    }}
                                >
                                    {Strings.generic.resetForm}
                                </Button>
                                <Button color="inherit" onClick={() => this.detailView.saveUserDetails()}>
                                    {Strings.generic.save}
                                </Button>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <AdminUserDetail
                        index={selectedUserIndex}
                        user={results[selectedUserIndex]}
                        innerRef={view => {
                            this.detailView = view;
                        }}
                        onSaveUser={user => this.saveUserDetail(user)}
                    />
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.user.authToken,
        stats: state.stats,
        results: state.admin.searchResults,
    };
};

const styledAdminUsers = withStyles(styles)(AdminUsers);
const styledAndSizedUsers = withSize({ refreshMode: 'debounce' })(styledAdminUsers);
export default connect(mapStateToProps)(styledAndSizedUsers);
