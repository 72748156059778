import React from 'react';
import { withSize } from 'react-sizeme';

const Widget = props => {
    const { children, size } = props;
    const betterChildren = React.Children.map(children, child =>
        React.cloneElement(child, { size })
    );
    return <div {...props}>{betterChildren}</div>;
};

export default withSize({ monitorHeight: true, refreshMode: 'debounce' })(
    Widget
);
