import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { withStyles } from '@material-ui/core/styles';

import 'react-grid-layout/css/styles.css';
import './dashboard.css';

const styles = () => ({
    container: {
        margin: '0 auto',
    },
});

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = props => {
    const { classes, children, ...others } = props;
    return (
        <div className={classes.container}>
            <ResponsiveGridLayout {...others}>{children}</ResponsiveGridLayout>
        </div>
    );
};

export default withStyles(styles)(Dashboard);
