import React from 'react';
import { connect } from 'react-redux';

import { Line } from '@nivo/line';

const UserCount = props => {
    const { size = { width: 600, height: 400 } } = props;
    const { width, height } = size;
    return (
        <Line
            width={width}
            height={height * 0.85}
            data={[
                {
                    id: 'japan',
                    color: 'hsl(84, 70%, 50%)',
                    data: [
                        {
                            x: 'plane',
                            y: 183,
                        },
                        {
                            x: 'helicopter',
                            y: 291,
                        },
                        {
                            x: 'boat',
                            y: 154,
                        },
                        {
                            x: 'train',
                            y: 78,
                        },
                        {
                            x: 'subway',
                            y: 176,
                        },
                        {
                            x: 'bus',
                            y: 54,
                        },
                        {
                            x: 'car',
                            y: 252,
                        },
                        {
                            x: 'moto',
                            y: 289,
                        },
                        {
                            x: 'bicycle',
                            y: 184,
                        },
                        {
                            x: 'others',
                            y: 7,
                        },
                    ],
                },
                {
                    id: 'france',
                    color: 'hsl(281, 70%, 50%)',
                    data: [
                        {
                            x: 'plane',
                            y: 37,
                        },
                        {
                            x: 'helicopter',
                            y: 127,
                        },
                        {
                            x: 'boat',
                            y: 4,
                        },
                        {
                            x: 'train',
                            y: 0,
                        },
                        {
                            x: 'subway',
                            y: 43,
                        },
                        {
                            x: 'bus',
                            y: 268,
                        },
                        {
                            x: 'car',
                            y: 108,
                        },
                        {
                            x: 'moto',
                            y: 280,
                        },
                        {
                            x: 'bicycle',
                            y: 48,
                        },
                        {
                            x: 'others',
                            y: 239,
                        },
                    ],
                },
                {
                    id: 'us',
                    color: 'hsl(101, 70%, 50%)',
                    data: [
                        {
                            x: 'plane',
                            y: 293,
                        },
                        {
                            x: 'helicopter',
                            y: 128,
                        },
                        {
                            x: 'boat',
                            y: 23,
                        },
                        {
                            x: 'train',
                            y: 242,
                        },
                        {
                            x: 'subway',
                            y: 106,
                        },
                        {
                            x: 'bus',
                            y: 66,
                        },
                        {
                            x: 'car',
                            y: 176,
                        },
                        {
                            x: 'moto',
                            y: 94,
                        },
                        {
                            x: 'bicycle',
                            y: 247,
                        },
                        {
                            x: 'others',
                            y: 49,
                        },
                    ],
                },
                {
                    id: 'germany',
                    color: 'hsl(122, 70%, 50%)',
                    data: [
                        {
                            x: 'plane',
                            y: 104,
                        },
                        {
                            x: 'helicopter',
                            y: 242,
                        },
                        {
                            x: 'boat',
                            y: 245,
                        },
                        {
                            x: 'train',
                            y: 279,
                        },
                        {
                            x: 'subway',
                            y: 48,
                        },
                        {
                            x: 'bus',
                            y: 92,
                        },
                        {
                            x: 'car',
                            y: 60,
                        },
                        {
                            x: 'moto',
                            y: 31,
                        },
                        {
                            x: 'bicycle',
                            y: 214,
                        },
                        {
                            x: 'others',
                            y: 60,
                        },
                    ],
                },
                {
                    id: 'norway',
                    color: 'hsl(113, 70%, 50%)',
                    data: [
                        {
                            x: 'plane',
                            y: 79,
                        },
                        {
                            x: 'helicopter',
                            y: 120,
                        },
                        {
                            x: 'boat',
                            y: 83,
                        },
                        {
                            x: 'train',
                            y: 203,
                        },
                        {
                            x: 'subway',
                            y: 30,
                        },
                        {
                            x: 'bus',
                            y: 29,
                        },
                        {
                            x: 'car',
                            y: 194,
                        },
                        {
                            x: 'moto',
                            y: 13,
                        },
                        {
                            x: 'bicycle',
                            y: 262,
                        },
                        {
                            x: 'others',
                            y: 274,
                        },
                    ],
                },
            ]}
            margin={{
                top: 50,
                right: 110,
                bottom: 50,
                left: 60,
            }}
            xScale={{
                type: 'point',
            }}
            yScale={{
                type: 'linear',
                stacked: true,
                min: 'auto',
                max: 'auto',
            }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'transportation',
                legendOffset: 36,
                legendPosition: 'middle',
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
            }}
            dotSize={10}
            dotColor="inherit:darker(0.3)"
            dotBorderWidth={2}
            dotBorderColor="#ffffff"
            enableDotLabel
            dotLabel="y"
            dotLabelYOffset={-12}
            animate={false}
            motionStiffness={90}
            motionDamping={15}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

const mapStateToProps = state => {
    return {
        stats: state.stats,
    };
};

export default connect(mapStateToProps)(UserCount);
