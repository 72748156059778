import axios from 'axios';
import { APIPrefix } from '../constants/FlareConfig';

export function signIn(email, password) {
    return axios.post(
        `${APIPrefix}/auth/login`,
        {
            email,
            password,
        },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
}

export function resetToken(email) {
    localStorage.removeItem('user');
    return axios.post(
        `${APIPrefix}/auth/reset`,
        JSON.stringify({
            email,
        }),
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
}

export function setPassword(email, token, password) {
    localStorage.removeItem('user');
    return axios.post(
        `${APIPrefix}/auth/reset/creds`,
        JSON.stringify({
            email,
            token,
            password,
        }),
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
}
