export default {
    admin: {
        home: {
            title: 'Home',
            widgets: {
                userCount: {
                    title: 'User Count',
                    graphTitle: 'Number of users over time',
                },
                eventViewer: {
                    emptyListMessage: 'List is empty',
                    title: 'Event Viewer',
                    timeRange: {
                        5: '5 mins',
                        10: '10 mins',
                        30: '30 mins',
                        60: '60 mins',
                    },
                    timeRangePrompt: 'Show last',
                    eventCount: {
                        single: ' event',
                        multiple: ' events',
                    },
                },
            },
        },
        jewelry: {
            title: 'Jewelry',
        },
        users: {
            title: 'Users',
            searchFieldName: 'Search by name, phone, email',
            searchButtonLabel: 'Search',
            clearButtonLabel: 'Reset search',
            detail: {
                title: 'User Detail',
                form: {
                    first_name: 'First Name',
                    last_name: 'Last Name',
                    phone: 'Phone',
                    email: 'Email',
                    active: 'Active',
                    viewed_tutorial: 'Completed onboarding',
                    created: 'Account created',
                    updated: 'Account updated',
                },
                neverHappened: 'Never',
            },
        },
    },
    generic: {
        email: 'Email',
        resetForm: 'Reset Form',
        save: 'Save',
    },
    notFound: {
        details: "Sorry about that. We'll make it better soon! If you're stuck please reach out and we'll help.",
        title: 'Not Found',
    },
    reset: {
        changePasswordLabel: 'Set new password',
        instructions: 'Fill out the form below to get started.',
        maxTriesExceeded: 'Max tries exceeded',
        nextStepButtonLabel: 'Next',
        passwordChanged: "Awesome! You're all set. Go sign in again.",
        passwordConfirmHelp: 'Must match the password field',
        passwordConfirmLabel: 'Confirm Password',
        passwordFailedReason0: 'Make sure you submit a new password within 5 minutes of entering your email address',
        passwordFailedReason1: 'Check that you entered the exact secret code we texted you',
        passwordFailedReason2: 'Your account will be locked if you try more than 4 times',
        passwordFailedReason3: 'Contact support if you need your account to be unlocked',
        passwordFailedText: 'Here are some things to try:',
        passwordFailedTitle: "Bummer! That didn't work.",
        passwordLabel: 'Your new Password',
        passwordMismatch: 'Does not match',
        passwordRequirements: 'Must be 8+ chars, including upper+lower case and a special char.',
        passwordStrengthHigh: 'Great',
        passwordStrengthLow: 'Weak',
        passwordStrengthMedium: 'Meh',
        title: "Alright, let's reset your password.",
        tokenInputLabel: 'The secret message we texted you',
    },
    signin: {
        error: 'Something went wrong. Try again.',
        forgotPasswordButtonLabel: 'Forgot Password',
        inputs: {
            email: 'Email',
            password: 'Password',
        },
        requestButtonLabel: 'Sign In',
        title: 'Sign In',
    },
    titleBar: {
        companyName: 'Get Flare',
        signOut: 'Sign Out',
    },
    user: {
        jewelry: {
            title: 'Jewelry',
        },
        privacy: {
            title: 'Privacy',
        },
        home: {
            comingSoon: 'Coming Soon',
        },
    },
};
