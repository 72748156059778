import Immutable from 'seamless-immutable';
import * as types from '../actions/types';
import initialState from './initialState';

export default function admin(state = initialState.admin, action = {}) {
    switch (action.type) {
    case types.SEARCH_USERS_REQUEST:
        return state.merge({
            loading: true,
        });
    case types.SEARCH_USERS_SUCCESS:
        return state.merge({
            loading: false,
            searchResults: action.data.results,
            searchQuery: action.data.query,
        });
    case types.SEARCH_USERS_FAILURE:
        return state.merge({
            loading: false,
        });

    case types.RESET_SEARCH_USERS:
        return state.replace({
            loading: false,
            searchQuery: '',
            searchResults: [],
        });

    case types.AUTH_RESET:
        return state.replace({
            loading: false,
            searchResults: [],
            searchQuery: null,
        });

    case types.UPDATE_USER_REQUEST:
        return state.merge({
            loading: true,
        });

    case types.UPDATE_USER_SUCCESS: {
        const searchResultIndex = state.searchResults.findIndex(r => r.id === action.data.user.id);
        if (searchResultIndex !== -1) {
            return state.merge({
                loading: false,
                searchResults: Immutable.set(state.searchResults, searchResultIndex, action.data.user),
            });
        }
    }
    // falls through

    default:
        return state;
    }
}
