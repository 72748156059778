import { APIPrefix } from '../constants/FlareConfig';
import ProtectedAPICall from '../bits/ProtectedAPICall';

export function getUserEvents(token, start, end, order) {
    const url = `${APIPrefix}/ua/events?start=${start}&end=${end}&order=${order}`;

    return ProtectedAPICall(token, url, {
        method: 'GET',
    });
}

export function getUsersByQuery(token, query) {
    const url = `${APIPrefix}/users/search?query=${query}`;

    return ProtectedAPICall(token, url, {
        method: 'GET',
    });
}

export function updateUser(token, user) {
    const url = `${APIPrefix}/users/${user.id}`;

    return ProtectedAPICall(token, url, {
        method: 'PUT',
        data: {
            ...user,
        },
    });
}
