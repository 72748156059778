import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'seamless-immutable';
import classNames from 'classnames';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getRecentEvents, setEventViewerTimeFrame } from '../../../actions/admin';
import FlareMap from '../../../bits/FlareMap';
import Strings from '../../../locales/en';
import EventViewerListItem from './EventViewerListItem';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '90%',
    },
    header: {
        flex: 1,
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: theme.spacing.medium,
        paddingLeft: theme.spacing.medium,
        paddingRight: theme.spacing.medium,
        paddingBottom: theme.spacing.large,
        fontSize: '0.8rem',
        backgroundColor: theme.palette.standards.veryLight,
    },
    timeRange: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: theme.spacing.medium,
    },
    timeRangeLabel: {
        marginRight: theme.spacing.small,
    },
    listAndMapContainer: {
        flex: 9,
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    list: {
        flex: 9,
        flexGrow: 1,
        overflow: 'auto',
    },
    listNextToMap: {
        flex: 1,
        flexGrow: 0.6,
    },
    map: {
        flex: 3,
        flexGrow: 2,
    },
    loadingSpinner: {
        width: '0.9rem',
        height: '0.9rem',
    },
    listEmpty: {
        margin: theme.spacing.medium,
        color: theme.palette.standards.grey,
    },
});

class EventViewer extends React.PureComponent {
    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        const { eventTimeFrame } = this.props;
        if (eventTimeFrame !== prevProps.eventTimeFrame) {
            this.refresh();
        }
    }

    refresh() {
        const { dispatch, eventTimeFrame, authToken } = this.props;
        const format = 'YYYY-MM-DD[T]HH:mm:ss';
        const now = moment().utc();
        const end = now.format(format);
        const start = now.subtract(eventTimeFrame, 'minutes').format(format);
        dispatch(getRecentEvents(authToken, start, end));
    }

    handleTimeFrameSelect(event) {
        const { dispatch } = this.props;
        dispatch(setEventViewerTimeFrame(event.target.value));
    }

    render() {
        const { size = { width: 600, height: 400 }, events, eventTimeFrame, eventTypes, classes, loading } = this.props;
        const eventCount = events.length;
        const { width, height } = size;
        const BREAKPOINT_FOR_MAP_TOGGLE = 788;
        const showMap = width >= BREAKPOINT_FOR_MAP_TOGGLE;
        return (
            <div className={classNames([classes.container])}>
                <div className={classNames([classes.header])}>
                    <div className={classNames([classes.timeRange])}>
                        <div className={classNames([classes.timeRangeLabel])}>
                            {Strings.admin.home.widgets.eventViewer.timeRangePrompt}
                        </div>
                        <Select value={eventTimeFrame} onChange={v => this.handleTimeFrameSelect(v)}>
                            {Object.keys(Strings.admin.home.widgets.eventViewer.timeRange).map(t => (
                                <MenuItem key={t} value={t}>
                                    {Strings.admin.home.widgets.eventViewer.timeRange[t]}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {loading && <CircularProgress className={classNames([classes.loadingSpinner])} />}
                    {!loading && (
                        <div className={classNames([classes.eventCount])}>
                            {eventCount}
                            {eventCount === 1
                                ? Strings.admin.home.widgets.eventViewer.eventCount.single
                                : Strings.admin.home.widgets.eventViewer.eventCount.multiple}
                        </div>
                    )}
                </div>
                {events.length === 0 && (
                    <div className={classNames([classes.listEmpty])}>
                        {Strings.admin.home.widgets.eventViewer.emptyListMessage}
                    </div>
                )}
                {events.length > 0 && (
                    <div className={classNames([showMap && classes.listAndMapContainer])}>
                        <div className={classNames([classes.list, showMap && classes.listNextToMap])}>
                            {events.map(e => (
                                <EventViewerListItem
                                    key={e.id}
                                    title={eventTypes[e.event_type_id]}
                                    isHome={e.is_home}
                                    isWearing={e.is_wearing}
                                    latitude={e.latitude}
                                    longitude={e.longitude}
                                    timestamp={e.timestamp}
                                    userID={e.user_id}
                                    deviceID={e.device_id}
                                />
                            ))}
                        </div>
                        {showMap && (
                            <div className={classNames([classes.map])}>
                                <FlareMap height={height} events={events} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

EventViewer.defaultProps = {
    events: [],
    eventTimeFrame: 10,
    loading: false,
};

const mapStateToProps = state => {
    return {
        authToken: Immutable.getIn(state, ['user', 'authToken']),
        events: Immutable.getIn(state, ['stats', 'events']),
        eventTypes: Immutable.getIn(state, ['stats', 'eventTypes']),
        eventTimeFrame: Immutable.getIn(state, ['stats', 'eventTimeFrame']),
        loading: Immutable.getIn(state, ['stats', 'loading']),
    };
};

const connectedEventViewer = connect(mapStateToProps)(EventViewer);
export default withStyles(styles)(connectedEventViewer);
