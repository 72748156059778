import { navigate } from 'hookrouter';
import { signIn as signInAction, setPassword, resetToken } from '../services/authService';
import Strings from '../locales/en';
import * as types from './types';

export function signIn(email, password) {
    function request() {
        return { type: types.AUTH_REQUEST };
    }
    function success(data) {
        return { type: types.AUTH_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.AUTH_FAILURE, error };
    }

    return dispatch => {
        dispatch(request());
        signInAction(email, password).then(
            response => dispatch(success(response.data)),
            error => dispatch(failure(error))
        );
    };
}

export function clearSignInError() {
    return async function doClear(dispatch) {
        dispatch({
            type: types.AUTH_CLEAR_ERROR,
        });
    };
}

export function signOut() {
    return async function doSignOut(dispatch) {
        dispatch({
            type: types.AUTH_RESET,
        });
        navigate('/', true);
    };
}

export function requestPasswordResetToken(email) {
    function request() {
        return { type: types.RESET_TOKEN_REQUEST };
    }
    function success() {
        return { type: types.RESET_TOKEN_SUCCESS };
    }
    function failure(error) {
        return { type: types.RESET_TOKEN_FAILURE, error };
    }

    return dispatch => {
        dispatch(request());
        resetToken(email).then(
            data => dispatch(success(data)),
            error => {
                const interpreted = error.response.status === 403 ? Strings.reset.maxTriesExceeded : 'wtf';
                dispatch(failure(interpreted));
            }
        );
    };
}

export function changePassword(email, token, newPassword) {
    function request() {
        return { type: types.CHANGE_PASSWORD_REQUEST };
    }
    function success() {
        return { type: types.CHANGE_PASSWORD_SUCCESS };
    }
    function failure(error) {
        return { type: types.CHANGE_PASSWORD_FAILURE, error };
    }

    return dispatch => {
        dispatch(request());
        setPassword(email, token, newPassword).then(() => dispatch(success()), error => dispatch(failure(error)));
    };
}
