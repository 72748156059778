import React from 'react';
import { connect } from 'react-redux';

import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pink } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import logo from '../../assets/img/logo-aura.png';
import { changePassword, requestPasswordResetToken } from '../../actions/auth';
import Strings from '../../locales/en';

const ResetStyles = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#F4F2EC',
        color: '#000',
        padding: '2rem',
    },
    logo: {
        height: 64,
        animation: 'fadein 3s',
        margin: '1rem 0 2rem',
    },
    header: {
        marginBottom: '2rem',
    },
    instructions: {},
    inputField: {
        width: '24rem',
        marginBottom: '1rem',
    },
    tokenField: {
        width: '24rem',
        marginBottom: '3rem',
    },
    buttonArea: {
        padding: '1rem 0',
        marginBottom: '2rem',
    },
    error: {
        marginBottom: '1rem',
        color: pink[500],
        padding: '1rem',
        border: `1px solid ${pink[500]}`,
    },
    commentary: {
        display: 'inline-block',
        marginLeft: '1rem',
        color: pink[500],
    },
};

class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            token: '',
            newPassword: '',
            newPasswordConfirm: '',
            passwordEntryError: false,
            newPasswordValid: false,
            newPasswordConfirmValid: false,
            passwordStrength: Strings.reset.passwordStrengthLow,
            readyToChangePassword: false,
        };
    }

    handleEmailInput(keyEvent) {
        if (keyEvent.key === 'Enter' && this.state.email.length > 0) {
            this.requestToken();
        }
    }

    handlePasswordConfirmInput(keyEvent) {
        const longEnough = this.state.newPassword.length >= 8;
        const matching = this.state.newPasswordConfirm === this.state.newPassword;
        const goodToGo = longEnough && matching;

        this.setState({
            passwordEntryError: goodToGo ? false : Strings.reset.passwordMismatch,
            readyToChangePassword: goodToGo,
        });
    }

    updateField(field, value) {
        const state = {};
        state[field] = value;
        if (field === 'newPassword') {
            const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
            const mediumRegex = new RegExp(
                '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
            );

            const longEnough = value.length >= 8;
            state.passwordStrength = Strings.reset.passwordStrengthLow;
            if (longEnough) {
                if (strongRegex.test(value)) {
                    state.passwordStrength = Strings.reset.passwordStrengthHigh;
                } else if (mediumRegex.test(value)) {
                    state.passwordStrength = Strings.reset.passwordStrengthMedium;
                }
            }
        }

        this.setState(state);
    }

    requestToken() {
        this.props.dispatch(requestPasswordResetToken(this.state.email));
    }

    setNewPassword() {
        this.props.dispatch(changePassword(this.state.email, this.state.token, this.state.newPassword));
    }

    render() {
        const {
            readyForUserToken,
            requestingToken,
            passwordHasBeenSet,
            sendingNewPassword,
            passwordResetError,
        } = this.props;
        return (
            <div style={ResetStyles.container}>
                <div>
                    <img src={logo} alt={Strings.reset.brandName} style={ResetStyles.logo} />
                </div>
                <TextField style={{ visibility: 'hidden' }} autocomplete="false" />
                {!passwordHasBeenSet && (
                    <div>
                        <div style={ResetStyles.header}>
                            <h1>{Strings.reset.title}</h1>
                            <div style={ResetStyles.instructions}>{Strings.reset.instructions}</div>
                        </div>
                        <TextField
                            autoFocus
                            value={this.state.email}
                            onChange={e => this.updateField('email', e.currentTarget.value)}
                            onKeyDown={e => this.handleEmailInput(e)}
                            label={Strings.generic.email}
                            style={ResetStyles.inputField}
                        />
                    </div>
                )}
                {this.state.email.length > 0 && !requestingToken && !passwordHasBeenSet && (
                    <div style={ResetStyles.buttonArea}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.requestToken()}
                            disabled={requestingToken || this.state.readyToChangePassword}
                        >
                            {Strings.reset.nextStepButtonLabel}
                        </Button>
                        {requestingToken && (
                            <div style={ResetStyles.commentary}>
                                <CircularProgress size={32} />
                            </div>
                        )}
                    </div>
                )}
                {requestingToken && (
                    <div>
                        <CircularProgress />
                    </div>
                )}
                {readyForUserToken && !passwordHasBeenSet && (
                    <div>
                        <div>
                            <TextField
                                autoFocus
                                value={this.state.token}
                                onChange={e => this.updateField('token', e.currentTarget.value)}
                                label={Strings.reset.tokenInputLabel}
                                style={ResetStyles.tokenField}
                            />
                        </div>
                        <div>
                            <TextField
                                value={this.state.newPassword}
                                type="password"
                                onChange={e => this.updateField('newPassword', e.currentTarget.value)}
                                label={Strings.reset.passwordLabel}
                                style={ResetStyles.inputField}
                                error={this.state.newPasswordValid}
                                helperText={Strings.reset.passwordRequirements}
                            />
                            <div style={ResetStyles.commentary}>{this.state.passwordStrength}</div>
                        </div>
                        <div>
                            <TextField
                                value={this.state.newPasswordConfirm}
                                type="password"
                                onChange={e => this.updateField('newPasswordConfirm', e.currentTarget.value)}
                                label={Strings.reset.passwordConfirmLabel}
                                style={ResetStyles.inputField}
                                onKeyUp={e => this.handlePasswordConfirmInput(e)}
                                error={this.state.newPasswordConfirmValid}
                                helperText={Strings.reset.passwordConfirmHelp}
                            />
                            <div style={ResetStyles.commentary}>{this.state.passwordEntryError}</div>
                        </div>
                        {this.state.readyToChangePassword && (
                            <div style={ResetStyles.buttonArea}>
                                <Button variant="contained" color="primary" onClick={() => this.setNewPassword()}>
                                    {Strings.reset.changePasswordLabel}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                {sendingNewPassword && (
                    <div>
                        <CircularProgress />
                    </div>
                )}
                {passwordHasBeenSet && (
                    <div>
                        <h1>{Strings.reset.passwordChanged}</h1>
                        <a href="/">{Strings.signin.requestButtonLabel}</a>
                    </div>
                )}
                {passwordResetError && (
                    <div>
                        <h1>{Strings.reset.passwordFailedTitle}</h1>
                        {passwordResetError && <div style={ResetStyles.error}>{passwordResetError}</div>}
                        <div style={ResetStyles.instructions}>{Strings.reset.passwordFailedText}</div>
                        <ul>
                            <li>{Strings.reset.passwordFailedReason0}</li>
                            <li>{Strings.reset.passwordFailedReason1}</li>
                            <li>{Strings.reset.passwordFailedReason2}</li>
                            <li>{Strings.reset.passwordFailedReason3}</li>
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        requestingToken: state.user.requestToken,
        readyForUserToken: state.user.readyForUserToken,
        sendingNewPassword: state.user.sendingNewPassword,
        passwordHasBeenSet: state.user.passwordHasBeenSet,
        passwordResetError: state.user.passwordResetError,
    };
}

const themedReset = withTheme()(Reset);
const connectedReset = connect(mapStateToProps)(themedReset);
export default connectedReset;
