import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Map from 'pigeon-maps';
import Marker from 'pigeon-marker';

const styles = theme => ({
    container: {},
});

const osm = (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
};

class FlareMap extends React.Component {
    constructor(props) {
        super(props);
        this.defaultCenter = [42.352677, -71.060886];
        this.state = {
            center: this.defaultCenter,
            zoom: 4,
        };
    }

    onBoundsChanged(b) {
        this.setState({
            center: b.center,
            zoom: b.zoom,
            bounds: b.bounds,
        });
    }

    render() {
        const { events } = this.props;
        const { center, zoom } = this.state;
        return (
            <Map
                provider={osm}
                center={center}
                defaultCenter={this.defaultCenter}
                zoom={zoom}
                onBoundsChanged={b => this.onBoundsChanged(b)}
                twoFingerDrag
            >
                {events.map(e => (
                    <Marker key={e.id} payload={e.title} anchor={[e.latitude, e.longitude]} />
                ))}
            </Map>
        );
    }
}

export default withStyles(styles)(FlareMap);
