import axios from 'axios';

async function getAuthorizationHeader(userToken) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    headers.Authorization = `Bearer ${userToken}`;
    return headers;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} token       The current user's auth token
 * @param  {string} route       The endpoint on the server we want to request
 * @param  {object} [options]   The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export default async function request(token, route, options) {
    const headers = await getAuthorizationHeader(token);
    const optionsWithHeaders = Object.assign({}, options, {
        headers,
        url: route,
    });

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.debug(JSON.stringify(optionsWithHeaders));
    }

    return axios(optionsWithHeaders);
}
