import React from 'react';
import { connect } from 'react-redux';

import { navigate } from 'hookrouter';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { AutomaticActionTimeout } from '../../constants/FlareConfig';
import { Failed } from '../../constants/AuthStates';
import { signIn, clearSignInError } from '../../actions/auth';
import Strings from '../../locales/en';
import logo from '../../assets/img/logo-aura.png';

const styles = theme => ({
    form: {
        width: '90%',
        margin: '0 auto',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            border: `1px solid ${theme.palette.primary.main}`,
            margin: '2rem auto',
            padding: '4rem',
            maxWidth: 480,
        },
    },
    logoArea: {
        flex: '2 0 auto',
        marginTop: '1rem',
        marginBottom: '4rem',
    },
    logo: {
        height: '5rem',
    },
    inputArea: {
        flex: '6 0 auto',
    },
    resetArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        [theme.breakpoints.up('sm')]: {
            padding: '3rem 2rem',
        },
    },
    textField: {
        display: 'block',
        '& + &': {
            marginTop: '1.4rem',
        },
    },
});

class SignIn extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const newState = {};

        // determine if error state has changed
        const authStateIsFailed = props.authState === Failed;
        const hasError = authStateIsFailed !== state.hasError;
        if (hasError) {
            newState.hasError = hasError;
        }

        // determine if root has changed
        if (props.root !== state.root) {
            navigate(props.root, true);
        }

        // only update state if something meaningful changed
        if (Object.keys(newState).length) {
            return newState;
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            hasError: false,
        };
    }

    onFieldChange(field, value) {
        this.setState({
            [field]: value,
        });
    }

    onPressSignIn() {
        const { dispatch } = this.props;
        const { email, password } = this.state;
        if (email.length && password.length) {
            dispatch(signIn(email, password));
        }
    }

    closeSnackBar() {
        const { dispatch } = this.props;
        dispatch(clearSignInError());
    }

    render() {
        const { classes } = this.props;
        const { email, password, hasError } = this.state;
        return (
            <React.Fragment>
                <div className={classes.form}>
                    <div className={classes.logoArea}>
                        <img src={logo} alt="Flare" className={classes.logo} />
                    </div>
                    <div className={classes.inputArea}>
                        <Typography variant="h1" color="primary">
                            {Strings.signin.title}
                        </Typography>
                        <TextField
                            id="username"
                            className={classes.textField}
                            label={Strings.signin.inputs.email}
                            value={email}
                            onChange={e => this.onFieldChange('email', e.target.value)}
                            fullWidth
                        />
                        <TextField
                            id="password"
                            className={classes.textField}
                            type="password"
                            label={Strings.signin.inputs.password}
                            value={password}
                            onChange={e => this.onFieldChange('password', e.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className={classes.resetArea}>
                        <Button onClick={() => navigate('/reset')}>{Strings.signin.forgotPasswordButtonLabel}</Button>
                    </div>
                    <Button variant="contained" fullWidth color="primary" onClick={() => this.onPressSignIn()}>
                        {Strings.signin.requestButtonLabel}
                    </Button>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        autoHideDuration={AutomaticActionTimeout}
                        open={hasError}
                        onClose={() => this.closeSnackBar()}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{Strings.signin.error}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.closeSnackBar()}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        autoHideDuration={AutomaticActionTimeout}
                        open={hasError}
                        onClose={() => this.closeSnackBar()}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{Strings.signin.error}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.closeSnackBar()}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        authState: state.user.authState,
        token: state.user.authToken,
        root: state.nav.root,
    };
}

const styledSignIn = withStyles(styles)(SignIn);
export default connect(mapStateToProps)(styledSignIn);
