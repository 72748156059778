import * as types from '../actions/types';
import initialState from './initialState';

import * as AuthStates from '../constants/AuthStates';

function getContactsCrewLookup(crew) {
    const memberKeys = crew.members.map(member => member.key);
    const contactsCrewLookup = {};
    memberKeys.forEach(key => {
        // @TODO: eventually the values in this object should be arrays of crew IDs.
        contactsCrewLookup[key] = crew.id;
    });
    return contactsCrewLookup;
}

export default function user(state = initialState.user, action = {}) {
    switch (action.type) {
    /**
         * AUTHENTICATION
         */
    case types.AUTH_FAILURE:
        return state.merge({
            token: null,
            authState: AuthStates.Failed,
        });
    case types.AUTH_REQUEST:
        return state.merge({
            token: null,
            authState: AuthStates.Requested,
        });
    case types.AUTH_SUCCESS: {
        const firstCrew =
                action.data && action.data.crews && action.data.crews.length
                    ? action.data.crews[0]
                    : { id: 0, members: [] };

        return state.merge({
            authToken: action.data.auth_token,
            analyticsToken: action.data.analytics_token,
            profile: action.data.profile,
            crews: action.data.crews,
            devices: action.data.devices,
            authState: AuthStates.Succeeded,
            role: action.data.role,
            hasViewedTutorial: !!action.data.viewed_tutorial,
            contactsCrewLookup: getContactsCrewLookup(firstCrew),
        });
    }
    case types.AUTH_CLEAR_ERROR:
        return state.merge({
            authState: null,
        });

    case types.AUTH_RESET:
        return initialState.user;

    case types.RESET_TOKEN_REQUEST:
        return state.merge({
            requestingToken: true,
            readyForUserToken: false,
            passwordResetError: false,
        });

    case types.RESET_TOKEN_SUCCESS:
        return state.merge({
            requestingToken: false,
            readyForUserToken: true,
            passwordResetError: false,
        });

    case types.RESET_TOKEN_FAILURE:
        return state.merge({
            requestingToken: false,
            readyForUserToken: false,
            passwordResetError: action.error,
        });

    case types.CHANGE_PASSWORD_REQUEST:
        return state.merge({
            sendingNewPassword: true,
            passwordHasBeenSet: false,
            passwordResetError: false,
        });

    case types.CHANGE_PASSWORD_SUCCESS:
        return state.merge({
            sendingNewPassword: false,
            passwordHasBeenSet: true,
            passwordResetError: false,
        });

    case types.CHANGE_PASSWORD_FAILURE:
        return state.merge({
            sendingNewPassword: false,
            passwordHasBeenSet: false,
            passwordResetError: true,
        });

    default:
        return state;
    }
}
