import * as types from '../actions/types';
import initialState from './initialState';

export default function stats(state = initialState.stats, action = {}) {
    switch (action.type) {
    case types.GET_RECENT_EVENTS_REQUEST:
        return state.merge({
            loading: true,
        });

    case types.GET_RECENT_EVENTS_SUCCESS:
        return state.merge({
            loading: false,
            events: action.data.events,
            eventTypes: action.data.eventTypes,
            homes: action.data.homes,
        });

    case types.GET_RECENT_EVENTS_FAILURE:
        return state.merge({
            loading: false,
        });

    case types.SET_RECENT_EVENTS_TIMEFRAME:
        return state.merge({
            eventTimeFrame: action.data.timeFrame,
        });

    case types.AUTH_RESET:
        return state.replace({
            loading: false,
            events: [],
            eventTypes: [],
            homes: [],
        });

    default:
        return state;
    }
}
