import React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'hookrouter';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import { signOut } from '../actions/auth';
import Strings from '../locales/en';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing.unit,
        alignItems: 'center',
    },
    menuButton: {
        display: 'inline-block',
    },
    signOutButton: {
        fontFamily: 'museo-sans-rounded, sans-serif',
        fontWeight: 700,
        fontSize: '0.8rem',
        padding: '0 1rem',
    },
    title: { display: 'inline-block' },
    titleArea: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
});

class TitleBar extends React.PureComponent {
    componentDidUpdate(prevProps) {
        const { token, root } = this.props;
        if (prevProps.token !== token && !token) {
            navigate(root, true);
        }
    }

    render() {
        const { classes, dispatch, onClickMenu } = this.props;
        return (
            <React.Fragment>
                <AppBar position="static" className={classes.container}>
                    <div className={classes.titleArea}>
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Menu"
                            onClick={() => onClickMenu()}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h4" color="inherit" className={classes.title}>
                            {Strings.titleBar.companyName}
                        </Typography>
                    </div>
                    <Button color="inherit" onClick={() => dispatch(signOut())} className={classes.signOutButton}>
                        {Strings.titleBar.signOut}
                    </Button>
                </AppBar>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.user.authToken,
        root: state.nav.root,
    };
}

const styledTitleBar = withStyles(styles)(TitleBar);
export default connect(mapStateToProps)(styledTitleBar);
