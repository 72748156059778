import { getUserEvents, getUsersByQuery, updateUser } from '../services/adminService';
import * as types from './types';

export function getRecentEvents(token, start, end) {
    function request() {
        return { type: types.GET_RECENT_EVENTS_REQUEST };
    }
    function success(response) {
        const { events, homes } = response.data;
        const eventTypes = {};
        response.data.event_types.forEach(t => {
            eventTypes[t.event_type_id] = t.name;
        });
        return {
            type: types.GET_RECENT_EVENTS_SUCCESS,
            data: {
                events,
                homes,
                eventTypes,
            },
        };
    }
    function failure(error) {
        return { type: types.GET_RECENT_EVENTS_FAILURE, error };
    }

    return dispatch => {
        dispatch(request());
        getUserEvents(token, start, end, 'desc').then(
            response => dispatch(success(response)),
            error => dispatch(failure(error))
        );
    };
}

export function setEventViewerTimeFrame(timeFrame) {
    return dispatch => {
        dispatch({
            type: types.SET_RECENT_EVENTS_TIMEFRAME,
            data: {
                timeFrame,
            },
        });
    };
}

export function searchForUsers(token, query) {
    function request() {
        return { type: types.SEARCH_USERS_REQUEST };
    }
    function success(response) {
        const { results } = response.data;
        return {
            type: types.SEARCH_USERS_SUCCESS,
            data: {
                query,
                results,
            },
        };
    }
    function failure(error) {
        return { type: types.SEARCH_USERS_FAILURE, error };
    }

    return dispatch => {
        dispatch(request());
        getUsersByQuery(token, query).then(response => dispatch(success(response)), error => dispatch(failure(error)));
    };
}

export function resetSearchForUsers() {
    return dispatch => {
        dispatch({ type: types.RESET_SEARCH_USERS });
    };
}

export function saveChangestoUser(token, user) {
    function request() {
        return { type: types.UPDATE_USER_REQUEST };
    }
    function success(response) {
        return {
            type: types.UPDATE_USER_SUCCESS,
            data: {
                user: response.data.user,
            },
        };
    }
    function failure(error) {
        return { type: types.UPDATE_USER_FAILURE, error };
    }

    return dispatch => {
        dispatch(request);
        updateUser(token, user).then(response => dispatch(success(response)), error => dispatch(failure(error)));
    };
}
