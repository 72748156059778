import React from 'react';
import { connect } from 'react-redux';

import 'react-grid-layout/css/styles.css';
import { common } from '@material-ui/core/colors';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Widget } from '../../bits/Dashboard';

import UserCount from './Widgets/UserCount';
import EventViewer from './Widgets/EventViewer';
import Strings from '../../locales/en';
import WidgetTitle from '../../bits/Dashboard/WidgetTitle';

const ReactGridLayout = WidthProvider(Responsive);
const styles = theme => ({
    grid: {
        backgroundColor: theme.palette.standards.cream,
        height: '100%',
    },
    gridItem: {
        boxShadow: `0 0 10px #e8e8e8`,
        backgroundColor: common.white,
        borderRadius: '4px',
    },
});

class AdminHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gridItems: [
                {
                    title: Strings.admin.home.widgets.eventViewer.title,
                    canClose: true,
                    body: <EventViewer />,
                },
                {
                    title: Strings.admin.home.widgets.userCount.title,
                    canClose: true,
                    body: <UserCount />,
                },
            ],
        };
    }

    removeGridItem(gridItemTitle) {
        const { gridItems } = this.state;
        const updatedItems = Array.from(gridItems).filter(item => item.title !== gridItemTitle);
        this.setState({
            gridItems: updatedItems,
        });
    }

    render() {
        const { classes } = this.props;
        const { gridItems } = this.state;
        const layouts = {
            xxs: [
                {
                    i: 'widget-Event Viewer',
                    x: 0,
                    y: 0,
                    w: 2,
                    h: 4,
                    minH: 2,
                },
                {
                    i: 'widget-User Count',
                    x: 0,
                    y: 7,
                    w: 2,
                    h: 6,
                    minH: 6,
                },
            ],
            xs: [
                {
                    i: 'widget-Event Viewer',
                    x: 0,
                    y: 0,
                    w: 4,
                    h: 4,
                    minH: 2,
                },
                {
                    i: 'widget-User Count',
                    x: 0,
                    y: 0,
                    w: 4,
                    h: 6,
                    minH: 6,
                },
            ],
            sm: [
                {
                    i: 'widget-Event Viewer',
                    x: 0,
                    y: 0,
                    w: 6,
                    h: 4,
                    minH: 2,
                },
                {
                    i: 'widget-User Count',
                    x: 0,
                    y: 0,
                    w: 6,
                    h: 4,
                    minH: 4,
                },
            ],
            md: [
                {
                    i: 'widget-Event Viewer',
                    x: 0,
                    y: 0,
                    w: 10,
                    h: 4,
                    minH: 2,
                },
                {
                    i: 'widget-User Count',
                    x: 0,
                    y: 0,
                    w: 10,
                    h: 4,
                    minH: 4,
                },
            ],
            lg: [
                {
                    i: 'widget-Event Viewer',
                    x: 0,
                    y: 0,
                    w: 12,
                    h: 4,
                    minH: 2,
                },
                {
                    i: 'widget-User Count',
                    x: 0,
                    y: 0,
                    w: 12,
                    h: 4,
                    minH: 4,
                },
            ],
        };
        return (
            <ReactGridLayout
                onLayoutChange={this.onLayoutChange}
                layouts={layouts}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={100}
                style={{ height: '100vh' }}
                className={classNames(['react-grid-layout', classes.grid])}
            >
                {gridItems.map(item => (
                    <Widget className={classNames(['react-grid-item', classes.gridItem])} key={`widget-${item.title}`}>
                        <WidgetTitle
                            title={item.title}
                            canClose={item.canClose}
                            onClose={toClose => this.removeGridItem(toClose)}
                        />
                        {item.body}
                    </Widget>
                ))}
            </ReactGridLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        stats: state.stats,
    };
};

const styledAdminHome = withStyles(styles)(AdminHome);
export default connect(mapStateToProps)(styledAdminHome);
