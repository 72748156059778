import React from 'react';
import { A } from 'hookrouter';
import { withStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

const styles = theme => ({
    link: {
        display: 'flex',
        color: theme.palette.standards.white,
        padding: '0.5rem 0',
        minWidth: '9rem',
        fontFamily: 'museo-sans-rounded, sans-serif',
        fontWeight: 700,
        textDecoration: 'none',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    icon: {
        flex: 1,
    },
    label: {
        flex: 4,
        lineHeight: 2,
    },
});

const FlareDrawerLink = props => {
    const { icon, label, classes, ...other } = props;
    return (
        <A className={classes.link} {...other}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.label}>{label}</div>
        </A>
    );
};

FlareDrawerLink.defaultProps = {
    icon: <ArrowForward />,
};

export default withStyles(styles)(FlareDrawerLink);
